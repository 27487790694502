<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    class="choose-selectedPlan-edit"
    v-loading="loading"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :beforeClose="beforeClose"
    append-to-body
    width="600px"
  >
    <el-form :model="form.data" label-width="190px" :rules="form.rules" ref="form">
      <el-form-item label="选任单位：">
        <el-input v-model="xrdw" disabled></el-input>
      </el-form-item>
      <el-form-item label="选任标题：" prop="jhmc">
        <el-input v-model="form.data.jhmc"></el-input>
      </el-form-item>
      <el-form-item label="选任开始年度" prop="jhksnd">
        <el-date-picker v-model="form.data.jhksnd" type="year" placeholder="请选择选任开始年度"></el-date-picker>
      </el-form-item>
      <el-form-item label="随机抽选人数：" prop="sjcxrs">
        <el-input v-model.number="form.data.sjcxrs"></el-input>
      </el-form-item>
      <el-form-item label="个人申请/组织推荐人数：" prop="bmrs">
        <el-input v-model.number="form.data.bmrs"></el-input>
      </el-form-item>
      <el-form-item label="报名开始时间：" prop="bmkssj">
        <el-date-picker v-model="form.data.bmkssj" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="报名截止时间：" prop="bmjssj">
        <el-date-picker v-model="form.data.bmjssj" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="附件资料：" v-if="auth.loadPDF" v-auth="'loadPDF'">
        <template>
          <div class="item" v-for="item in form.data.attach" :key="item.id">
            <div class="name">{{item.name}}</div>
            <i class="el-icon-circle-close" @click="removeAttach(item)"></i>
          </div>
          <div
            v-loading="file_loading"
            element-loading-text="上传文件中"
            class="upload_file"
            @click="StartSelectFile"
            @drop="drop"
            @dragenter="dragenter"
            @dragleave="dragleave"
            @dragover="dragover"
            v-show="form.data.attach.length === 0"
          >
            <i class="el-icon-upload"></i>
            <div class="text">
              将PDF文件拖拽到此处，或
              <span class="blue">点击上传</span>
            </div>
          </div>
        </template>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
    <input type="file" ref="file" class="inputfile" v-if="selectFile" @change="change" />
  </el-dialog>
</template>

<script>
import SelectPlan from '@/api/choose/selectPlan';
export default {
  name: 'edit',
  props: {
    title: {
      tyle: String,
    },
  },
  components: {},
  data() {
    return {
      form: {
        data: {
          jhmc: '',
          xrdw: '',
          jhksnd: new Date().getFullYear().toString(),
          sjcxrs: '',
          bmrs: '',
          bmkssj: '',
          bmjssj: '',
          jhlx: '',
          attach: [],
        },
        rules: {
          jhmc: [{ required: true, message: '请输入名称', trigger: 'change' }],
          jhksnd: [{ required: true, message: '请选择选任开始年度', trigger: 'change' }],
          sjcxrs: [{ required: true, message: '请输入随机抽选人数', trigger: 'change' }],
          bmrs: [{ required: true, message: '请输入报名人数', trigger: 'change' }],
          bmkssj: [{ required: true, message: '请选择报名开始时间', trigger: 'change' }],
          bmjssj: [{ required: true, message: '请选择报名结束时间', trigger: 'change' }],
        },
      },
      show: false,
      selectFile: false,
      loading: false,
      type: '',
      xrdw: '',
      auth: {
        loadPDF: true,
      },
      file_loading: false,
    };
  },
  methods: {
    beforeClose(done) {
      done();
      this.$emit('changeShow', false);
    },
    submit() {
      this.$refs.form.validate((valid) => {
        console.log(this);
        if (valid) {
          this.loading = true;

          SelectPlan[this.type](this.form.data)
            .then((res) => {
              console.log(res);
              this.$message.closeAll();
              this.$message.success(this.title + '成功');
              this.$emit('getList');
            })
            .catch((e) => {
              console.log(e);
              this.$message.closeAll();
              this.$message.error(this.title + '失败');
            })
            .finally(() => {
              this.loading = false;
              return this.toggle(false);
            });
        }
      });
    },
    toggle(show, data, type) {
      this.show = show;
      if (type) {
        this.type = type;
      }
      if (show && type === 'update' && data) {
        this.form.data = data;
      }
      if (!show) {
        this.$emit('changeShow', false);
      }
    },
    // 重置
    reset() {
      this.$refs.form.resetFields();
    },
    // 移除文件
    removeAttach(data) {
      this.$refs.file.value = '';
      this.form.data.attach.some((item, index) => {
        if (item.id === data.id) {
          this.form.data.attach.splice(index, 1);
          return true;
        }
      });
    },
    // 文件变化
    change(e) {
      let file = e.target.files[0];
      let { type, size } = file;
      if (type !== 'application/pdf') {
        this.$message.error('请选择PDF文件');
        this.removeAttach();
        return;
      }
      if (size / 1024 / 1024 > 10) {
        this.$message.error('您选择的PDF超过了10M');
        this.removeAttach();
        return;
      }
      let formdata = new FormData();
      formdata.append('file', file);
      this.file_loading = true;
      SelectPlan.loadPDF(formdata)
        .then((res) => {
          console.log(res);
          let { path, name } = res;
          this.form.data.attach.push({ name, path });
        })
        .finally(() => {
          this.file_loading = false;
        });
    },
    // 选择文件
    StartSelectFile() {
      this.selectFile = true;
      this.$nextTick(() => {
        this.$refs.file.click();
      });
    },
    //拖离
    dragenter(e) {
      e.preventDefault();
    },
    dragleave(e) {
      e.preventDefault();
    },
    //拖来拖去 , 一定要注意dragover事件一定要清除默认事件
    //不然会无法触发后面的drop事件
    dragover(e) {
      e.preventDefault();
    },
    //拖拽获取文件事件
    drop(e) {
      let a = { target: { files: [e.dataTransfer.files[0]] } };
      this.change(a);
      e.preventDefault();
    },
  },
  mounted() {},
  created() {
    this.xrdw = this.$store?.getters?.account?.org?.name;
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.el-dialog {
  .el-form {
    .el-date-editor {
      width: 100%;
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    .el-select {
      width: 100%;
    }
  }
  .inputfile {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
  .upload_file {
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 360px;
    height: 180px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .el-icon-upload {
      font-size: 67px;
      color: #c0c4cc;
      margin: 40px 0 16px;
      line-height: 50px;
    }
    .text {
      .blue {
        color: #409eff;
      }
    }
  }
  .upload_file:hover {
    border-color: #409eff;
    cursor: pointer;
  }
}
</style>

/**
 * @functionName getXRDW
 * @param { Object }
 * @param { Object }  table ，table的data字段
 * @param { Object }  table_field ，要转化的字段
 * @param { Array  }  OrgTree，字典中对应字段的children
 * @Description  将选任单位由id转变为中文
 * @return { Object } table ,其中，转化后的字段为 field_a
 */
export default function getXRDW(table, field, OrgTree) {
  let result = table.map(table_item => {
    OrgTree.some(item => {
      if (item.id === table_item[field]) {
        table_item[field + '_a'] = item.name
        return true
      }
    })
    table.forEach(item => {
      if (item.has_child) {
        item.children = this.getXRDW(item.children, field, OrgTree)
      }
    })
    return table_item
  })
  return result
}
